import { createApp } from 'vue'
import App from './App.vue'

// // Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import { getDatabase } from "firebase/database"; // 新增這行
// // TODO: Add SDKs for Firebase products that you want to use
// // https://firebase.google.com/docs/web/setup#available-libraries

// // Your web app's Firebase configuration
// // For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//     apiKey: "AIzaSyBBzcwJLjMH1ndEb4Ej1iXFegN82Zx51f4",
//     authDomain: "mom-light-test-website.firebaseapp.com",
//     databaseURL: "https://mom-light-test-website-default-rtdb.firebaseio.com",
//     projectId: "mom-light-test-website",
//     storageBucket: "mom-light-test-website.appspot.com",
//     messagingSenderId: "542732139340",
//     appId: "1:542732139340:web:c1590b2b1ad44c42412de9",
//     measurementId: "G-WZP6MMCMRF"
// };

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
// const database = getDatabase(app); // 初始化 Realtime Database
// analytics;
// database;

createApp(App).mount('#app')