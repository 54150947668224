<!-- index.js -->
<template style="padding-top: 80px;">
    <!-- 導覽列 -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light fixed-top">
        <div class="container-fluid">
            <!--logo-->
            <a class="navbar-brand">
            </a>

            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-end" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" @click="showSection('about')">關於我們</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="showSection('services')">服務項目</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="showSection('product')">周邊商品</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="showSection('worker')">樂安月嫂</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" @click="showSection('reserve')">預約月嫂</a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>

    <!-- 輪播banner -->
    <div id="carouselExample" class="carousel slide" data-bs-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <!-- 添加文字層 -->
                <div class="carousel-caption">
                    <h5>{{ banner_text }}</h5>
                </div>
                <img src="@/assets/banner/ca4.png" class="d-block w-100" alt="First Slide">
            </div>
            <div class="carousel-item">
                <img src="@/assets/banner/ca3.png" class="d-block w-100" alt="Third Slide">
            </div>
        </div>
        <button class="carousel-control-prev" type="button" data-bs-target="#carouselExample" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
        </button>
        <button class="carousel-control-next" type="button" data-bs-target="#carouselExample" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
        </button>
    </div>

    <!-- 在這裡插入跑馬燈 -->
    <div style="display: flex; align-items: center; justify-content: center; height: 50px; background-color: white;">
        <marquee style="color: blue; font-family: Arial, sans-serif; font-size: 24px;">
            親愛的產家您好~母嬰預約月嫂流程需要您基本的需求表單,您可以直接加專員的賴諮詢line  id :fi0031 或@944mkyxj賴官網諮詢
        </marquee>
    </div>

    <!--關於我們-->
    <div v-show="sections['about']" id="about" class="content-section">
        <div class="container my-5">
            <div class="row">
                <!-- 文字區塊 -->
                <div class="col-md-6">
                    <h2 class="text-left my-4">關於我們</h2>
                    <p>樂安母嬰是一家專業提供全省（各縣市含離島）海外月嫂及保姆服務的機構。我們的月嫂團隊均接受專業培訓，持有合格證照，並擁有豐富的經驗。我們致力於以最專業、嚴謹的態度，提供最全面、最細心的照顧，確保媽咪與寶寶得到最完善的調理服務。
                    </p>
                </div>
                <!-- 圖片區塊 -->
                <div class="col-md-6">
                    <img src="@/assets/about.png" class="img-fluid" alt="描述您的圖片">
                </div>
            </div>
        </div>
    </div>

    <div v-show="sections['services']" id="services" class="content-section" style="display:none;">
        <div class="container my-5">
            <div id="servicesCarousel" class="carousel slide" data-bs-ride="carousel">
                <!-- Indicators/dots -->
                <div class="carousel-indicators">
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="0" class="active"
                        aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="1"
                        aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="2"
                        aria-label="Slide 3"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="3"
                        aria-label="Slide 4"></button>
                    <button type="button" data-bs-target="#servicesCarousel" data-bs-slide-to="4"
                        aria-label="Slide 5"></button> 
                </div>

                <!-- 輪播內容 -->
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src="@/assets/server/ser1.png" class="d-block w-100 h-120" alt="服務項目1">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/server/ser2.png" class="d-block w-100 h-120" alt="服務項目2">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/server/ser3.png" class="d-block w-100 h-120" alt="服務項目3">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/server/ser4.png" class="d-block w-100 h-120" alt="服務項目4">
                    </div>
                    <div class="carousel-item">
                        <img src="@/assets/server/ser5.png" class="d-block w-100 h-120" alt="服務項目5">
                    </div>                    
                </div>
                <!-- 左右控制按鈕 -->
                <button class="carousel-control-prev" type="button" data-bs-target="#servicesCarousel" data-bs-slide="prev">
                    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Previous</span>
                </button>
                <button class="carousel-control-next" type="button" data-bs-target="#servicesCarousel" data-bs-slide="next">
                    <span class="carousel-control-next-icon" aria-hidden="true"></span>
                    <span class="visually-hidden">Next</span>
                </button>
            </div>
        </div>
    </div>

      <!-- ... 其他部分 ... -->

    <!-- 週邊商品 -->
    <div v-show="sections['product']" id="product" class="content-section">
        <div class="container my-5">
            <div class="row">
                <div class="col-md-4" v-for="(product, index) in paginatedProducts" :key="index">
                    <img :src="product.image" class="img-fluid" alt="Product Image">
                </div>
            </div>
            <nav aria-label="Page navigation">
                <ul class="pagination">
                    <li class="page-item" v-for="n in productPageCount" :key="n">
                        <a class="page-link" :class="{ 'active': productCurrentPage === n }" href="#" @click.prevent="setProductPage(n)">
                            {{ n }}
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <!--雅元月嫂-->
    <div v-show="sections['worker']" id="worker" class="content-section" style="text-align: center;">
    <div class="container my-5">
        <div class="row">
            <div class="col-md-4" v-for="(imgSrc, index) in paginatedImages" :key="index">
                <img :src="imgSrc" class="worker-image img-fluid" alt="Image">
            </div>
        </div>
        <div id="pagination">
            <ul class="pagination">
                <li v-for="page in pagesToDisplay" :key="page">
                <a href="#" @click.prevent="changePage(page)"
                    :class="{'active': currentPage === page}">
                    {{ page }}
                </a>
                </li>
            </ul>
        </div>
    </div>
    </div>


    <!--預約功能-->
    <div v-show="sections['reserve']" id="reserve" class="content-section">
        <div v-if="!submitSuccessful" class="container my-5">
            <h2 class="text-center my-4">預約月嫂</h2>
            <form ref="form" @submit.prevent="submitForm">
                <!-- 姓名 -->
                <div class="mb-3">
                    <label for="name" class="form-label">姓名</label>
                    <input type="text" class="form-control" v-model="form.name" id="name" required>
                </div>

                <!-- 地址 -->
                <div class="mb-3">
                    <label for="address" class="form-label">地址</label>
                    <input type="text" class="form-control" v-model="form.address" id="address" maxlength="250" required>
                </div>

                <!-- 年齡 -->
                <div class="mb-3">
                    <label for="age" class="form-label">年齡</label>
                    <input type="number" class="form-control" v-model.number="form.age" id="age" min="1" max="150" required>
                </div>

                <!-- 預產期 -->
                <div class="mb-3">
                    <label for="dueDate" class="form-label">預產期</label>
                    <input type="date" class="form-control" v-model="form.dueDate" id="dueDate" required>
                </div>

                <!-- 每日需服務時數 -->
                <div class="mb-3">
                    <label for="dailyHours" class="form-label">每日需服務時數</label>
                    <input type="number" class="form-control" v-model.number="form.dailyHours" id="dailyHours" min="1"
                        max="24" required>
                </div>

                <!-- 需服務天數 -->
                <div class="mb-3">
                    <label for="serviceDays" class="form-label">需服務天數</label>
                    <input type="number" class="form-control" v-model.number="form.serviceDays" id="serviceDays" min="1"
                        max="250" required>
                </div>

                <!-- 第幾胎 -->
                <div class="mb-3">
                    <label for="birthOrder" class="form-label">第幾胎</label>
                    <input type="number" class="form-control" v-model.number="form.birthOrder" id="birthOrder" min="1"
                        max="100" required>
                </div>

                <!-- 生產方式 -->
                <div class="mb-3">
                    <label for="birthMethod" class="form-label">生產方式</label>
                    <input type="text" class="form-control" v-model="form.birthMethod" id="birthMethod" required>
                </div>

                <!-- 房屋類型 -->
                <div class="mb-3">
                    <label for="houseType" class="form-label">房屋類型</label>
                    <select class="form-select" v-model="form.houseType" id="houseType" required>
                        <option disabled value="">選擇...</option>
                        <option value="透天厝">透天厝</option>
                        <option value="房屋">電梯大樓</option>
                        <option value="公寓">公寓</option>
                        <option value="公寓">三合院</option>
                        <!-- 添加更多選項 -->
                    </select>
                </div>

                <!-- 電話 -->
                <div class="mb-3">
                    <label for="phone" class="form-label">電話</label>
                    <input type="tel" class="form-control" v-model="form.phone" id="phone" required>
                </div>

                <!-- LINE ID -->
                <div class="mb-3">
                    <label for="lineId" class="form-label">LINE ID</label>
                    <input type="text" class="form-control" v-model="form.lineId" id="lineId" required>
                </div>

                <!-- LINE的名稱 -->
                <div class="mb-3">
                    <label for="lineName" class="form-label">LINE的名稱</label>
                    <input type="text" class="form-control" v-model="form.lineName" id="lineName" required>
                </div>

                <!-- 方便連絡時間 -->
                <div class="mb-3">
                    <label for="contactTime" class="form-label">方便連絡時間</label>
                    <select class="form-select" v-model="form.contactTime" id="contactTime" required>
                        <option disabled value="">選擇...</option>
                        <option value="上午(7:00~12:00)">上午(7:00~12:00)</option>
                        <option value="下午(12:01~18:00)">下午(12:01~18:00)</option>
                        <option value="晚上(18:01~00:00)">晚上(18:01~00:00)</option>
                    </select>
                </div>

                <!-- 信箱 -->
                <div class="mb-3">
                    <label for="email" class="form-label">信箱</label>
                    <input type="email" class="form-control" v-model="form.email" id="email" required>
                </div>

                <!-- 備註需求 -->
                <div class="mb-3">
                    <label for="additionalNotes" class="form-label">備註需求</label>
                    <textarea class="form-control" v-model="form.additionalNotes" id="additionalNotes" rows="3"
                        required></textarea>
                </div>

                <button v-bind:disabled="submited" type="submit" class="btn btn-primary">提交</button>
                <h3 v-show="submited">請等待. . .</h3>
            </form>
        </div>
        <div v-else class="container my-5">
            <h2 class="text-center my-4">預約成功！</h2>
        </div>
    </div>

    <!--FB、LINE連結-->
    <aside>
        <!-- Facebook分享圖標 -->
        <a href="https://reurl.cc/5Oe7ky " target="_blank">
            <i class="bi bi-facebook"></i>
        </a>
        <!-- LINE分享圖標 -->
        <a href="https://line.me/ti/p/@808pvanw " target="_blank">
            <i class="bi bi-line"></i>
        </a>
    </aside>

    <!-- 底部頁腳 -->
    <footer>
        <div class="text-center p-3" style="background-color: rgba(0, 0, 0, 0.2);">
            &copy; 2023 - All Rights Reserved.
        </div>
    </footer>
</template>

<!-- script.js -->
<script>
import emailjs from '@emailjs/browser';


export default {
    data() {
        return {
            images: [
            require('@/assets/worker/worker1.jpg'),
                require('@/assets/worker/worker1-1.jpg'),
                require('@/assets/worker/worker2.jpg'),
                require('@/assets/worker/worker2-1.jpg'),
                require('@/assets/worker/worker3.jpg'),
                require('@/assets/worker/worker3-1.jpg'),
                require('@/assets/worker/worker4.jpg'),
                require('@/assets/worker/worker4-1.jpg'),
                require('@/assets/worker/worker4-2.jpg'),
                require('@/assets/worker/worker5.jpg'),
                require('@/assets/worker/worker5-1.jpg'),
                require('@/assets/worker/worker6.jpg'),
                require('@/assets/worker/worker6-1.jpg'),
                require('@/assets/worker/worker7.jpg'),
                require('@/assets/worker/worker7-1.jpg'),
                require('@/assets/worker/worker7-2.jpg'),
                require('@/assets/worker/worker8.jpg'),
                require('@/assets/worker/worker8-1.jpg'),
                require('@/assets/worker/worker9.png'),
                require('@/assets/worker/worker9-1.png'),
                require('@/assets/worker/worker9-2.png'),
                require('@/assets/worker/worker10.png'),
                require('@/assets/worker/worker10-1.png'),
                require('@/assets/worker/worker10-2.png'),
                require('@/assets/worker/worker10-3.png'),
                require('@/assets/worker/worker11.png'),
                require('@/assets/worker/worker11-1.png'),
                require('@/assets/worker/worker11-2.png'),
                require('@/assets/worker/worker12.png'),
                require('@/assets/worker/worker12-1.png'),
                require('@/assets/worker/worker12-2.png'),
                require('@/assets/worker/worker12-3.png'),
                require('@/assets/worker/worker13.png'),
                require('@/assets/worker/worker13-1.png'),
                require('@/assets/worker/worker13-2.png'),
                require('@/assets/worker/worker14.png'),
                require('@/assets/worker/worker14-1.png'),
                require('@/assets/worker/worker14-2.png'),
                require('@/assets/worker/worker15.png'),
                require('@/assets/worker/worker15-1.png'),
                require('@/assets/worker/worker15-2.png'),
                require('@/assets/worker/worker15-3.png'),
                require('@/assets/worker/worker16.png'),
                require('@/assets/worker/worker16-1.png'),
                require('@/assets/worker/worker16-2.png'),
                require('@/assets/worker/worker16-3.png'),
                require('@/assets/worker/worker17.png'),
                require('@/assets/worker/worker17-1.png'),
                require('@/assets/worker/worker17-2.png'),
                require('@/assets/worker/worker17-3.png'),
                require('@/assets/worker/worker18.png'),
                require('@/assets/worker/worker18-1.png'),
                require('@/assets/worker/worker18-2.png'),
                require('@/assets/worker/worker19.png'),
                require('@/assets/worker/worker19-1.png'),
                require('@/assets/worker/worker19-2.png'),
                require('@/assets/worker/worker19-3.png'),
                require('@/assets/worker/worker20.png'),
                require('@/assets/worker/worker20-1.png'),
                require('@/assets/worker/worker20-2.png'),
                require('@/assets/worker/worker20-3.png'),
                require('@/assets/worker/worker21.png'),
                require('@/assets/worker/worker21-1.png'),
                require('@/assets/worker/worker21-2.png'),
                require('@/assets/worker/worker21-3.png'),
                require('@/assets/worker/worker22.png'),
                require('@/assets/worker/worker22-1.png'),
                require('@/assets/worker/worker22-2.png'),
                require('@/assets/worker/worker22-3.png'),
                require('@/assets/worker/worker22-4.png'),
                require('@/assets/worker/worker23.png'),
                require('@/assets/worker/worker23-1.png'),
                require('@/assets/worker/worker23-2.png'),
                require('@/assets/worker/worker23-3.png'),
                require('@/assets/worker/worker23-4.png'),
                require('@/assets/worker/worker24.png'),
                require('@/assets/worker/worker24-1.png'),
                require('@/assets/worker/worker24-2.png'),
                require('@/assets/worker/worker24-3.png'),
                require('@/assets/worker/worker25.png'),
                require('@/assets/worker/worker25-1.png'),
                require('@/assets/worker/worker25-2.png'),
                require('@/assets/worker/worker25-3.png'),
                require('@/assets/worker/worker26.png'),
                require('@/assets/worker/worker26-1.png'),
                require('@/assets/worker/worker26-2.png'),
                require('@/assets/worker/worker26-3.png'),
                require('@/assets/worker/worker27.png'),
                require('@/assets/worker/worker27-1.png'),
                require('@/assets/worker/worker27-2.png'),
                require('@/assets/worker/worker28.png'),
                require('@/assets/worker/worker28-1.png'),
                require('@/assets/worker/worker28-2.png'),
                require('@/assets/worker/worker28-3.png'),
                require('@/assets/worker/worker28-4.png'),
                require('@/assets/worker/worker28-5.png'),
                require('@/assets/worker/worker29.png'),
                require('@/assets/worker/worker29-1.png'),
                require('@/assets/worker/worker29-2.png'),
            ],
            productCurrentPage: 1,
            productItemsPerPage: 9,
            products: [
                { image: require('@/assets/product/1.png')},
                { image: require('@/assets/product/2.png')},
                { image: require('@/assets/product/3.png')},   
                { image: require('@/assets/product/4.png')},
                { image: require('@/assets/product/5.png')},
                { image: require('@/assets/product/6.png')},  
                { image: require('@/assets/product/7.png')},
                { image: require('@/assets/product/8.png')},
                { image: require('@/assets/product/9.png')},
                { image: require('@/assets/product/10.png')},  
                { image: require('@/assets/product/11.png')},
                { image: require('@/assets/product/12.png')},
                { image: require('@/assets/product/13.png')},  
                { image: require('@/assets/product/space.png')},  
                { image: require('@/assets/product/space.png')},  
            ],
            currentPage: 1,
            itemsPerPage: 9,
            submitSuccessful: false,
            submited: false,
            banner_text: "關於我們",
            sections: {
                about: true,
                services: false,
                product: false,
                worker: false,
                reserve: false,
            },
            form: {
                name: '',
                address: '',
                age: null,
                dueDate: '',
                dailyHours: null,
                serviceDays: null,
                birthOrder: null,
                birthMethod: '',
                houseType: '',
                phone: '',
                lineId: '',
                lineName: '',
                contactTime: '',
                email: '',
                additionalNotes: ''
            }
        };
    },


    computed: {
        paginatedProducts() {
            const start = (this.productCurrentPage - 1) * this.productItemsPerPage;
            const end = start + this.productItemsPerPage;
            return this.products.slice(start, end);
        },
        productPageCount() {
            return Math.ceil(this.products.length / this.productItemsPerPage);
        },
        paginatedImages() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.images.slice(start, end);
        },
        pageCount() {
            return Math.ceil(this.images.length / this.itemsPerPage);
        },
        pagesToDisplay() {
            const pages = [];
            let startPage = this.currentPage - 2;
            let endPage = this.currentPage + 2;

            // 確保起始頁面不小於1
            if (startPage < 1) {
                startPage = 1;
                endPage = 5;
            }

            // 確保結束頁面不大於總頁數
            if (endPage > this.pageCount) {
                endPage = this.pageCount;
                // 調整開始頁面以保持顯示5個頁碼（如可能）
                startPage = Math.max(1, endPage - 4);
            }

            // 生成頁碼範圍
            for (let i = startPage; i <= endPage; i++) {
                pages.push(i);
            }

            return pages;
        }
        },
        methods: {
            setProductPage(pageNumber) {
                this.productCurrentPage = pageNumber;
            },
            showSection(section) {
                Object.keys(this.sections).forEach((key) => {
                    this.sections[key] = false;
                });

                this.sections[section] = true;
                switch (section) {
                    case "about":
                        this.banner_text = "關於我們";
                        break;
                    case "services":
                        this.banner_text = "服務項目";
                        break;
                    case "product":
                        this.banner_text = "週邊商品";
                        break;
                    case "worker":
                        this.banner_text = "樂安月嫂";
                        break;
                    case "reserve":
                        this.submitSuccessful = false;
                        this.banner_text = "預約月嫂";
                        break;
                }
        },

        // 下面不要動
        submitForm() {
            this.submited = true;
            emailjs.send('service_v0ygtqa', 'template_q53ok6a', this.form, 'U7di3_jysq8k8rE0G')
               .then(() => {
                    //console.log('SUCCESS!', result.text);
                    this.submitSuccessful = true;
                   this.submited = false;
                   alert('預約成功！');
               }, (e) => {
                    alert('發生錯誤！請通知網站工程人員。' + e);
            });
        },
        changePage(page) {
            this.currentPage = page;
        }
    }
};
</script>

<!-- style.css -->
<style>
body {
    padding-top: 133px;
}

#carouselExample .carousel-item img {
    width: 100%;
    height: 600px;
    object-fit: cover;
}

aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    bottom: 10px;
    right: 10px;
    z-index: 1030;
    background-color: white;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

aside a {
    margin: 5px;
}

aside a .bi {
    font-size: 18px;
    /* 調整圖標大小 */
}

.navbar-brand img {
    max-height: 100px;
    width: auto;
}

.carousel-caption {
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    padding: 10px;
    border-radius: 5px;
}

.carousel-caption h5 {
    font-size: 20px;
    margin-bottom: 5px;
}

.carousel-caption p {
    font-size: 15px;
}

.carousel-caption {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    color: white;
    background-color: rgba(0, 0, 0, 0.5);
    padding: 10px;
    border-radius: 5px;
}

.navbar-nav .nav-item .nav-link:hover {
    border-radius: 8px;
    background-color: #fb8989;
    color: #ffffff;
}

.nav-item {
    padding-right: 50px;
}

.navbar-brand {
    padding-left: 80px;
}

img {
    max-width: 100%;
    height: auto;
}

#employeeList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

.image-container {
    flex: 1 0 25%;
    margin: 20px;
}

.about-page #worker {
    display: block;
}


/* 分頁容器樣式 */
.pagination {
  display: inline-block;
  padding-left: 0;
  margin: 20px 0;
  border-radius: 4px;
}

 /* 分頁容器樣式 */
 .pagination {
        display: flex;
        justify-content: center;
        list-style: none;
        padding: 0;
        margin: 20px 0;
    }

    /* 分頁按鈕樣式 */
    .pagination li {
        margin: 0 5px;
    }

    .pagination li a {
        color: #000000;
        background-color: #fff;
        border: 1px solid #dee2e6;
        text-decoration: none;
        padding: 5px 10px;
        border-radius: 10px; /* 圓角樣式 */
        transition: all 0.3s ease;
    }

    /* 當前頁面按鈕樣式 */
    .pagination li a.active {
        background-color: #fb8989;
        color: #fff;
        border-color: #fb8989;
    }

    .pagination li a:hover:not(.active) {
        background-color: #e9ecef;
        border-color: #dee2e6;
    }


.worker-image {
    width: 100%;
    height: auto;
    margin-bottom: 10px; /* 為圖片添加一些間隔 */
}

#product .row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}

#product .col-md-4 {
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
    padding: 15px;
    box-sizing: border-box;
}

#product img {
    width: 100%;
    height: auto;
    margin-bottom: 15px; /* 為圖片添加一些間隔 */
}

</style>

<!-- mobile.css -->
<style>
@media (max-width: 767px) {
    #carouselExample .carousel-item img {
        height: 400px;
        margin-top: 0px;
    }
}

@media (max-width: 768px) {
    .navbar-brand img {
        max-height: none;
        margin-top: 10px;
        object-fit: contain;
    }

    @media (max-width: 767px) {
        body {
            padding-top: 100px;
        }
    }

    .navbar-brand {
        padding-left: 0;
    }

    .nav-item {
        padding-left: 0;
    }

    .nav-item #t {
        padding-right: 0;
    }

    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }

    #carouselExample .carousel-item img {
        height: 300px;
    }
}

@media (max-width: 767px) {
    #carouselExample .carousel-item img {
        height: 300px;
        object-fit: cover;
    }
}

@media (max-width: 768px) {
    .navbar-brand img {
        max-height: none;
        height: 60px;
        object-fit: contain;
        margin-top: 10px;
    }

    .navbar {
        padding-top: 0;
        padding-bottom: 0;
    }

    .nav-item,
    .nav-item #t {
        padding-left: 0;
        padding-right: 0;
    }

    #carouselExample .carousel-item img {
        height: 300px;
        object-fit: cover;
    }

    .image-container {
        flex: 1 0 48%;
    }

    @media (max-width: 767px) {
        body {
            padding-top: 100px;
        }

        #carouselExample .carousel-item img {
            height: 400px;
        }
    }
}

@media (max-width: 576px) {
    #carouselExample .carousel-item img {
        height: 200px;
    }
}

@media (max-width: 768px) {
    .image-container {
        flex: 1 0 48%;
    }
}
</style>